import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';
import ForthcomingCourses from '../components/ForthcomingCourses';
import AplicationProcess from '../components/AplicationProcess';
import ReviewsSection from '../components/ReviewsSection';
import Faq from '../components/Faq';

import IconArrowRight from '../assets/images/svg/arrow-right.inline.svg';
import IconArrowRightRed from '../assets/images/svg/arrow-right-red.inline.svg';
import iconCalendar from '../assets/images/svg/calendar.svg';
import iconClock from '../assets/images/svg/clock.svg';
import iconTick from '../assets/images/svg/tick.svg';
import iconAccomodation from '../assets/images/student-support/Accommodation (1).svg';
import iconStudentVisa from '../assets/images/student-support/Student visas (1).svg';
import iconCulture from '../assets/images/student-support/Culture.svg';
import iconBureaucracy from '../assets/images/student-support/Bureaucracy.svg';

import imgBenefit1 from '../assets/images/blended-trinity-cert-tesol-course-page/Internationally Recognized Qualification (2).svg';
import imgBenefit2 from '../assets/images/blended-trinity-cert-tesol-course-page/Practical Classroom Experience  (1).svg';
import imgBenefit3 from '../assets/images/blended-trinity-cert-tesol-course-page/Post-Grad Job Prospects 2 (1).svg';
import imgBenefit5 from '../assets/images/blended-trinity-cert-tesol-course-page/Self-Paced Online Study.svg';
import imgReview from '../assets/images/trinity-cert-tesol-course-page/Review photo.jpg';
import iconQuotes from '../assets/images/trinity-cert-tesol-course-page/quotes.svg';
import imgDots from '../assets/images/trinity-cert-tesol-course-page/Dots.svg';
import imgDevelopingTeacherCourse from '../assets/images/other-popular-courses/Developing Teacher Course.png';
import imgTeachYoungLearners from '../assets/images/other-popular-courses/Teacher Young Learners (1).png';
import imgIntensiveSpanish from '../assets/images/other-popular-courses/Intensive Spanish Course (1).png';

import fileTrinityCertTESOLSyllabus from '../assets/files/CertTESOL-syllabus-Jan-2016.pdf';
import fileTEFLIberiaBrochure from '../assets/files/TEFL Iberia brochure web.pdf';

import '../assets/styles/pages/blended-trinity-tesol-course-page.scss';

const BlendedTrinityCertTesol = ({ data }) => {
  const allPrismicCourses = data.allPrismicCourses.edges;

  return (
    <Layout
      title="Blended Trinity CertTESOL Course | Combined Online & Classroom TEFL"
      description="Start studying online and complete your course in Barcelona. 3 weeks of online study and 2 weeks of practical TEFL course in Barcelona."
    >
      <div className="blended-trinity-cert-tesol-course-page">
        {/* <section className="c-first-section c-first-section--sm">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-9 col-xl-7">
                <div className="c-first-section__schedule">
                  <div className="item__wrapper">
                    <img src={iconCalendar} alt="" />
                    <span className="c-text-14">5 weeks</span>
                  </div>
                  <div className="item__wrapper">
                    <img src={iconClock} alt="" />
                    <span className="c-text-14">160-hour course</span>
                  </div>
                </div>
                <h1 className="c-first-section__title">Blended Trinity CertTESOL Course</h1>
                <p className="c-first-section__description">
                  Combine online study and in-person classes in Barcelona to get an internationally
                  recognised qualification.
                </p>
                <div className="c-first-section__btns">
                  <a href="/apply/" className="c-btn c-btn--red">
                    Apply Now
                    <IconArrowRight />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="second-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className="c-title-34 second-section__l">Start Your Teacher Training Today</h2>
                <p className="c-text-18 description second-section__l">
                  Our blended Trinity CertTESOL course combines the best of both worlds - online
                  study at your own pace and teaching practice in exciting Barcelona with real
                  students. You’ll be prepared and qualified to find work here in Barcelona, or
                  wherever your travels may take you.
                  <span className="c-text-18 notice">
                    3 weeks online (self paced) | 2 weeks in person
                  </span>
                </p>
                <div className="c-benefit-list second-section__l">
                  <h4 className="c-text-16">This course is ideal for you if:</h4>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      You are unable to attend a 4-week course in Barcelona but still want the
                      international experience.
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      You want to maximise your work opportunities with first-class training and
                      internationally recognised Trinity CertTESOL qualification.
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">You enjoy online study.</p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">You want to start immediately.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="wrapper">
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit1} alt="" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Internationally Recognized Qualification</h3>
                      <p className="c-text-14">
                        Barcelona? Bangkok? Brazil? Trinity CertTESOL is accepted across the globe.
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit2} alt="" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Practical Classroom Experience</h3>
                      <p className="c-text-14">
                        You’ll build confidence by teaching groups of real students.
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit3} alt="" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Post-Grad Job Prospects</h3>
                      <p className="c-text-14">
                        We work hard to ensure that all our students find relevant work after
                        graduation.
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit5} alt="" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Self-Paced Online Study</h3>
                      <p className="c-text-14">
                        Get started any time with our modern online platform.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section c-section-includes c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="block_l">
                  <div className="block_l__img">
                    <img src={imgReview} alt="" className="img-main" />
                    <img src={imgDots} alt="" className="dots" />
                  </div>
                  <div className="block_l__text">
                    <img src={iconQuotes} alt="" />
                    <p>
                      «Completing the TEFL course here was a wonderful experience! The tutors and
                      community of the school made it the ideal place to take this course by
                      providing the trainees with all the information they need, but at the same
                      time making it challenging enough to resemble teaching life outside the
                      school. You get to learn different teaching techniques, create your own
                      authentic teaching material and experience what it’s like to be a teacher!».
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="block_r">
                  <h2 className="c-title-34">What’s included?</h2>
                  <div className="c-benefit-list block_r__benefits">
                    <h3 className="subtitle c-text-18">Online Course (80 hours):</h3>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">80-hour Blended Online Trinity CertTESOL course.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Expert tuition from professional teachers via our online platform, video
                        conferencing and discussion forums.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Access to your own online portal with all online course material (video
                        tutorials, interactive tasks, written assignments).
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Dedicated course coordinator.</p>
                    </div>

                    <h3 className="subtitle mt-50 c-text-18">In-Person Course (80 hours):</h3>

                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        160-hour Trinity CertTESOL course and qualification.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Trinity CertTESOL examination and certificate fees.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Expert tuition from professional teachers</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Access to a beautiful school library, high-speed wifi, computers.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        6+ hours of observed teaching practice with real learners.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Job contacts and placement help – our host school hires exclusively from
                        TEFL Iberia graduates.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Dedicated course coordinator.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Can be completed within 12 months of online start date.
                      </p>
                    </div>
                  </div>
                  <div className="block_r__price block_r__price--full">
                    <h3 className="c-text-13">Full Price:</h3>
                    <h3 className="c-title-32">1400€</h3>
                  </div>
                  <div className="block_r__price block_r__price--early-bird">
                    <h3 className="c-text-13">Early Bird Price:</h3>
                    <h3 className="c-title-32">1300€</h3>
                  </div>
                  <div className="block_r__btns">
                    <Link to="/apply" className="c-btn c-btn--red">
                      Apply Now
                      <IconArrowRight />
                    </Link>
                    <a
                      href={fileTEFLIberiaBrochure}
                      className="c-btn c-btn--red-border"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download Brochure
                      <IconArrowRightRed />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="fourth-section">
          <div className="container">
            <ForthcomingCourses cardData={allPrismicCourses} title="Upcoming Course Dates" />
          </div>
        </section>

        <section className="fifth-section c-student-support-section">
          <div className="container">
            <h2 className="c-title-34">Student support</h2>
            <p className="c-text-18">Learn about our additional services</p>
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconAccomodation} alt="" />
                  <h3 className="c-title-22">Accomodation</h3>
                  <Link to="/accommodation/" className="c-btn c-btn--white">
                    Find Out More
                    <IconArrowRightRed />
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconStudentVisa} alt="" />
                  <h3 className="c-title-22">Student Visa</h3>
                  <Link to="/working-as-a-teacher/student-visas" className="c-btn c-btn--white">
                    Find Out More
                    <IconArrowRightRed />
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconCulture} alt="" />
                  <h3 className="c-title-22">Culture</h3>
                  <Link to="/guide-to-living-and-teaching-english-in-barcelona/" className="c-btn c-btn--white">
                    Find Out More
                    <IconArrowRightRed />
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <img src={imgDots} className="dots" alt="" />
                <div className="item">
                  <img src={iconBureaucracy} alt="" />
                  <h3 className="c-title-22">Bureaucracy</h3>
                  <Link to="/working-as-a-teacher/student-visas" className="c-btn c-btn--white">
                    Find Out More
                    <IconArrowRightRed />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sixth-section">
          <AplicationProcess />
        </section>

        <section className="seventh-section">
          <div className="container">
            <h2 className="c-title-38">
              Start Your Overseas <span className="colored no-link">Adventure Today</span>
            </h2>
            <Link to="/apply/" className="c-btn c-btn--red">
              Sign Up Now
              <IconArrowRight />
            </Link>
          </div>
        </section>

        <section className="eigth-section c-faqs-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12">
                <h3 className="c-title-34">Course Curriculum and Timetable</h3>
                <p className="c-text-18">
                  The Blended Trinity CertTESOL course is 160 hours long and can be completed in 5
                  weeks: 3 weeks online and 2 weeks in Barcelona.{' '}
                  <span className="text-fw-600">
                    The timetable in Barcelona is Monday - Friday, 09.30 - 18.00.
                  </span>
                </p>

                <div className="assesment-block">
                  <h4 className="c-title-20">Is English not your native language?</h4>
                  <p className="c-text-16">
                    You need to demonstrate a C1 level in order to be able to do the course.
                  </p>
                  <a
                    href="https://www.tryinteract.com/share/quiz/5fa530f1849de80014ec5fbc"
                    className="c-btn c-btn--red-border"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Take English Assessment
                    <IconArrowRightRed />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <Faq title="Week 1" description="Week 1 description" />
                <Faq title="Week 2" description="Week 2 description" />
                <Faq title="Week 3" description="Week 3 description" />
                <Faq title="Week 4" description="Week 4 description" />
                <Faq title="Assesment Criteria" description={assesmentCriteria} />
                <a
                  target="_blank"
                  href={fileTrinityCertTESOLSyllabus}
                  className="c-btn c-btn--red mt-50"
                >
                  Download Trinity CertTESOL Syllabus
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="nineth-section">
          <ReviewsSection
            hideAllReviews
            description="Check out what these past students had to say"
            title="We’ve Helped Hundreds Of Students Qualify As A Teacher And Find Work In Overseas. Are You Next? "
          />
        </section>

        <section className="tenth-section c-faqs-section">
          <div className="container">
            <div className="c-faqs-section__title">
              <h2 className="c-title-34">Frequently Asked Questions</h2>
              <Link to="/courses/faq" className="c-btn c-btn--white">
                View More FAQs
                <IconArrowRightRed />
              </Link>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Faq
                  title="Does this course qualify me to teach English?"
                  description="Yes. The Trinity CertTESOL certificate, is one of few courses which are recognised by governments and schools internationally."
                />
                <Faq
                  title="How much will I get paid as an English teacher?"
                  description="The average rate of pay nowadays for new teachers is 12-15€/hour. Working for an academy full time (20 hours) you can expect to earn 1100€/month after tax. If you add on 2 private classes per week (it is easy to do more) you can increase that to 1300€/month."
                />
                <Faq
                  description="No. English is the only language used in the classroom and we want to show you how you can teach without using a word of the foreign language."
                  title="Do I need to speak Spanish to teach English in Spain?"
                />
              </div>
              <div className="col-lg-6">
                <Faq
                  description="Normally two weeks of continued job searching. We run our job finding workshop early-on in the course so you can start looking for jobs before the end of the course."
                  title="How long will it take me to find a job?"
                />
                <Faq
                  description="If you are a European Union citizen then you are free to work and study in any of the EU countries. If you are a non-EU citizen then you can stay up to three months as a tourist and you are free to study and do private classes during this time. If you would like to stay longer and obtain a work permit, the easiest way to do this is with a student visa, which will allow you to work up to 20 hours per week (which is full time for an English teacher). TEFL Iberia can process a student visa for you, for more detailed information please contact classes@ilabarcelona.com."
                  title="Do I need a visa?"
                />
                <Faq
                  description="You can expect to pay 500-550€ for a quality double room in a shared flat in the city centre, then about 140€/week on food and entertainment – this money will stretch quite far as Barcelona can be very cheap compared to the UK / US / Canada / Australia."
                  title="What is the average cost of living in Barcelona?"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="eleventh-section c-other-courses-section">
          <div className="container">
            <h2 className="c-title-34">
              Other <span className="colored no-link">Popular Courses</span>
            </h2>
            <p className="c-text-18">Take your training to the next level with TEFL Iberia</p>
            <div className="row">
              <div className="col-sm-6 col-lg-4">
                <Link to="/courses/developing-teacher-course" className="item">
                  <img
                    src={imgDevelopingTeacherCourse}
                    alt="imgDevelopingTeacherCourse"
                    className="item__img"
                  />
                  <p className="c-text-14">
                    <span className="item__price">120€ </span>
                    /month
                  </p>
                  <p className="c-text-20">Developing Teacher Course</p>
                </Link>
              </div>
              <div className="col-sm-6 col-lg-4">
                <Link to="/teaching-kids-course/" className="item">
                  <img
                    src={imgTeachYoungLearners}
                    alt="imgTeachYoungLearners"
                    className="item__img"
                  />
                  <p className="c-text-14">
                    <span className="item__price">300€ </span>
                    /3 day course
                  </p>
                  <p className="c-text-20">Teaching Young Learners</p>
                </Link>
              </div>
              <div className="col-sm-6 col-lg-4">
                <Link to="/tefl-spanish-course-barcelona/" className="item">
                  <img src={imgIntensiveSpanish} alt="imgIntensiveSpanish" className="item__img" />
                  <p className="c-text-14">
                    <span className="item__price">300€ </span>
                    /month
                  </p>
                  <p className="c-text-20">Intensive Spanish Course</p>
                </Link>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicCourses(
      filter: {
        data: {
          show_on_these_pages: { elemMatch: { page: { eq: "blended-trinity-cert-tesol-course" } } }
        }
      }
      sort: { order: ASC, fields: data___from }
    ) {
      edges {
        node {
          id
          data {
            name {
              text
            }
            from(formatString: "D MMMM YYYY")
            to(formatString: "D MMMM YYYY")
            price
            is_price_per_month
          }
        }
      }
    }
  }
`;

export default BlendedTrinityCertTesol;
